import React from "react"
import styled from "styled-components"
import { breakpoints } from "../theme"
import { body1, title2_Emphasis } from "./global-styles"
import { GatsbyImage } from "gatsby-plugin-image";
import "./squared-grid-item.scss";

const Wrapper = styled.div`
  margin-bottom: 56px;
  @media (min-width: ${breakpoints.medium}) {
    margin-bottom: 0;
  }
`
const Title = styled.h4`
  ${title2_Emphasis};
  margin-bottom: 18px;
  @media (min-width: ${breakpoints.medium}) {
    margin-bottom: 32px;
  }
`
const Body = styled.p`
  ${body1};
`
const ListItem = styled.p`
  margin-bottom: 5px;
`

const SetupItem = ({ image, title, body, items, className}) => {
  return (
    <Wrapper className="d-flex flex-column">
      <div
        style={{
          width: "100%",
          height: "165px",
          marginBottom: "37px",
        }}
      >
        <GatsbyImage
          objectFit="contain"
          className={className}
          image={image}
          alt={title} />
      </div>
      <Title>{title}</Title>
      {!!body && <Body>{body}</Body>}
      {!!items && (
        <div>
          {items.map((item, i) => (
            <ListItem className="body1" key={i.toString()}>{item}</ListItem>
          ))}
        </div>
      )}
    </Wrapper>
  )
}

export default SetupItem
