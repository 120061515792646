import React from "react"
import styled from "styled-components"
import { title1 } from "./global-styles"

const StyledText = styled.h3`
  ${title1};
  margin-bottom: 32px;
`

const SectionTitle = ({ children }) => {
  return <StyledText>{children}</StyledText>
}

export default SectionTitle
