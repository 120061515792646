import React from "react"
import styled from "styled-components"
import { breakpoints } from "../theme"

const Wrapper = styled.div`
  margin-top: 46px;
  @media (min-width: ${breakpoints.medium}) {
    margin-top: 35px;
  }
`

const PageWrapper = ({ children }) => {
  return <Wrapper className="container">{children}</Wrapper>
}

export default PageWrapper
