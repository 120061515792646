import React from "react"
import Slider from "react-slick"
import styled, { withTheme } from "styled-components"
import ProcessItems from "./process-items"
import SectionTitle from "./section-title"

const Wrapper = styled.div`
  & .title {
    margin-bottom: 64px;
  }
`

const productTeamContent = {
  message: "Drop us a line, give us a call or schedule a meeting with us.",
  quote: "Get a quote for the team of professionals you are looking to hire.",
  meeting: "Meet the team to clarify all doubts before starting.",
  contract: "Let's fine-tune the details and get started with the free week trial.",
};

const teamAugmentationContent = {
  message: "Drop us a line, give us a call or schedule a meeting with us.",
  quote: "Get a quote for the profiles that could be added to your team.",
  meeting: "Meet the professionals to clarify all doubts before starting.",
  contract: "Let’s fine-tune the details and get started with the free week trial.",
};

const ProcessBlock = ({ theme, productTeam }) => {
  const sliderSettings = {
    autoplay: false,
    vertical: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  const content = productTeam ? productTeamContent : teamAugmentationContent;

  return (
    <Wrapper>
      <div className="title">
        <SectionTitle>Next Steps</SectionTitle>
      </div>
      <Slider {...sliderSettings}>
        <ProcessItems
          bgColor={theme.highlightProcessBox.bgColor}
          textColor={theme.highlightProcessBox.textColor}
          button
          className="first"
          title="Message"
          content={content.message}
        />
        <ProcessItems
          bgColor={theme.body}
          textColor={theme.text}
          title="Quote"
          content={content.quote}
        />
        <ProcessItems
          bgColor={theme.body}
          textColor={theme.text}
          title="Meeting"
          content={content.meeting}
        />
        <ProcessItems
          bgColor={theme.body}
          textColor={theme.text}
          className="last"
          title="Contract"
          content={content.contract}
        />
      </Slider>
    </Wrapper>
  )
}

export default withTheme(ProcessBlock)
