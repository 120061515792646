import React from "react"
import styled from "styled-components"
import { body1_Emphasis, title1 } from "./global-styles"

const Title = styled.h1`
  ${title1};
  margin-bottom: 10px;
`

const BodyH2 = styled.h2`
  ${body1_Emphasis};
  max-width: 100%;
  margin-bottom: 0;
`
const BodyH3 = styled.h3`
  ${body1_Emphasis};
  font-size: 15px;
  max-width: 100%;
  margin-bottom: 0;
`

const PageHeader = ({ title, body }) => {
  return (
    <div>
      <Title>{title}</Title>
      {Array.isArray(body) ? (
        body.map((b, i) => b.props.type === "h3" ? <BodyH3 key={i.toString()}>{b}</BodyH3> : <BodyH2 key={i.toString()}>{b}</BodyH2>)
      ) : (
        <BodyH2>{body}</BodyH2>
      )}
    </div>
  )
}

export default PageHeader
