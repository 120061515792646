import React from "react"
import styled, { useTheme } from "styled-components"
import Layout from "../../components/layout"
import PageHeader from "../../components/page-header"
import PageWrapper from "../../components/page-wrapper"
import SquaredGridItem from "../../components/squared-grid-item"
//import RoundedGridItem from "../../components/rounded-grid-item"
import { breakpoints } from "../../theme"
import SectionTitle from "../../components/section-title"
import FreeTrialBlock from "../../components/free-trial-block"
import ProcessBlock from "../../components/process-block"
import { PRODUCT_DELIVERY_TEAM_ALT } from "../../constants/Constants"
import IntlText from "../../components/intl-text"
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import "./product-team.scss";

const banner = {
  dark: "delivery-team/dark/product-desktop.png",
  light: "delivery-team/light/product-desktop.png",
};

const bannerMobile = {
  dark: "delivery-team/dark/product-mobile.png",
  light: "delivery-team/light/product-mobile.png",
};

const FreeTrialContainer = styled.div`
  margin-top: 122px;
  margin-bottom: 157px;
  @media (min-width: ${breakpoints.medium}) {
    margin-bottom: 120px;
  }
`

const TechnologiesContainer = styled.div`
  margin-top: 0;
  @media (min-width: ${breakpoints.medium}) {
    margin-top: 120px;
  }
`

const ProcessContainer = styled.div`
  margin-top: 50px;

  @media (min-width: ${breakpoints.medium}) {
    margin-top: 120px;
  }
`

const setups = [
  {
    title: "Standalone Team",
    imageDark: "delivery-team/dark/standard-team.png",
    image: "delivery-team/light/standard-team.png",
    className:"image-squared-grid-one",
    body:
      "A self-sufficient and autonomous team in charge of 100% of your product. You set the vision and our team takes care of everything else.",
  },
  {
    title: "Team of Specialists",
    imageDark: "delivery-team/dark/team-of-specialists.png",
    image: "delivery-team/light/team-of-specialists.png",
    className:"image-squared-grid-one",
    body:
      "A team of specialists in a certain area (eg: Mobile App for iOS & Android). Our team will take care of that specific part of your business.",
  },
  {
    title: "Complementary Team",
    imageDark: "delivery-team/dark/complementary-team.png",
    className:"image-squared-grid-one",
    image: "delivery-team/dark/complementary-team.png",
    body:
      "A partial team that joins your team to fill in any gaps you may be experiencing. For example, a UIUX & Frontend team that joins your backend team.",
  },
]

const technologies = [
  {
    title: "Expertise",
    imageDark: "delivery-team/dark/expertise.png",
    image: "delivery-team/light/expertise.png",
    className:"image-squared-grid-two",
    items: [
      "Web Apps",
      "Mobile Apps",
      "Desktop Apps",
      "UI/UX Design",
      "Dev Ops",
      "Architecture"
    ],
  },

  {
    title: "Industries",
    className:"image-squared-grid-three",
    imageDark: "delivery-team/dark/industries.png",
    image: "delivery-team/light/industries.png",
    items: [
      "eCommerce",
      "Fintech",
      "Insuretech",
      "Security",
      "Health",
      "Travel"
    ],
  },

  {
    title: "Technologies",
    className:"image-squared-grid-three",
    imageDark: "delivery-team/dark/technologies.png",
    image: "delivery-team/light/technologies.png",
    items: [
      "JavaScript",
      "Node.js",
      "React.js",
      "React Native",
      "Next.js",
      "Gatsby",
    ],
  },
  {
    title: "Methodologies",
    className:"image-squared-grid-three",
    imageDark: "delivery-team/dark/methodologies.png",
    image: "delivery-team/light/methodologies.png",
    items: [
      "Scrum",
      "Planning",
      "Grooming",
      "Retrospective",
      "Review"
    ],
  },
]

const ProductDeliveryTeam = () => {
  const { isDark } = useTheme();
  const data = useStaticQuery(graphql`
  query imageProductTeam {
    Images: allFile(filter: {relativePath: { regex: "/delivery-team/"}, base: {nin: ["product-desktop.png", "product-mobile.png"]}}){
      edges {
        node {
          id
          base
          relativePath
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED,
                formats: [WEBP]
              )
            }
        }
      }
    }

    productDesktop: allFile(filter: {relativePath: { regex: "/product-desktop.png/"}}){
      edges {
        node {
          id
          base
          relativePath
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED,
                formats: [WEBP]
              )
            }
        }
      }
    }

    productMobile: allFile(filter: {relativePath: { regex: "/product-mobile.png/"}}){
      edges {
        node {
          id
          base
          relativePath
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED,
                formats: [WEBP]
              )
            }
        }
      }
    }
  }
`);

  return (
    <Layout
      pageTitle="Services"
      title="A product development team to scale your work - Sysgarage"
      description="We offer a product development team for your business to speed or scale your web or app development. Contact Us!"
    >
      <PageWrapper>
        <PageHeader
          title={<IntlText id="pages.product_team.title" />}
          body={<IntlText type="h2" id="pages.product_team.subtitle" />}
        />

        <div className="d-none d-md-flex justify-content-center">
          <GatsbyImage
            className="graph-draw"
            imgClassName="graph-draw-img"
            image={getImage(data.productDesktop.edges.filter((i) => i.node.relativePath.includes(isDark ? banner.dark : banner.light))[0].node.childImageSharp)}
            alt={PRODUCT_DELIVERY_TEAM_ALT} />
        </div>

        <div className="d-md-none">
          <GatsbyImage
            objectFit="contain"
            className="graph-draw"
            image={getImage(data.productMobile.edges.filter((i) => i.node.relativePath.includes(isDark ? bannerMobile.dark : bannerMobile.light))[0].node.childImageSharp)}
            alt={PRODUCT_DELIVERY_TEAM_ALT} />
        </div>

        <div className="section-container">
          <SectionTitle>Flexible Set-ups</SectionTitle>
          <p className="section-subtitle">
            {`You can scale up & down whenever you need`}
          </p>
          <div className="row" style={{ marginTop: "64px" }}>
            {setups.map((s, i) => (
              <div className="col-md-4" key={i}>
                <SquaredGridItem
                  className={s.className}
                  image={getImage(data.Images.edges.filter((i) => i.node.relativePath.includes(isDark ? s.imageDark : s.image))[0].node.childImageSharp)}
                  title={s.title}
                  body={s.body}
                />
              </div>
            ))}
          </div>
        </div>

        <div className="section-container">
          <TechnologiesContainer className="row">
            {technologies.map((t, i) =>
            (
              <div className="col-md-3" key={i}>
                <SquaredGridItem
                  className={t.className}
                  image={getImage(data.Images.edges.filter((i) => i.node.relativePath.includes(isDark ? t.imageDark : t.image))[0].node.childImageSharp)}
                  title={t.title}
                  items={t.items}
                />
              </div>
            ))}
          </TechnologiesContainer>
        </div>

        <div className="section-container">
          <FreeTrialContainer>
            <FreeTrialBlock />
          </FreeTrialContainer>
        </div>

        <ProcessContainer>
          <ProcessBlock productTeam />
        </ProcessContainer>
      </PageWrapper>
    </Layout>
  )
}

export default ProductDeliveryTeam
