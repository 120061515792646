import React from "react"
import styled, { withTheme } from "styled-components"
import { breakpoints } from "../theme"
import Button from "./button"
import { Link } from "gatsby"
import { title1 } from "./global-styles"
import { StaticImage } from "gatsby-plugin-image";
import "./free-trial-block.scss";

const Wrapper = styled.div`
  overflow-x: hidden;
  min-height: 565px;
  margin-bottom: 62px;
  @media (min-width: ${breakpoints.medium}) {
    width: auto;
    margin-bottom: 0;
    min-height: unset;
  }
`
const Content = styled.div`
  min-height: 515px;
  position: relative;
  & .action {
    margin-top: 64px;
  }
  @media (min-width: ${breakpoints.medium}) {
    min-height: auto;
  }
`
const Title = styled.h3`
  ${title1};
  margin-bottom: 32px;
`

const FreeTrialBlock = ({ theme }) => {
  return (
    <Wrapper>
      <Content>
        <div className="row align-items-center">
          <div className="col-12 col-md-6 d-md-flex justify-content-center">
            {theme.isDark ?
              <StaticImage
                className="free-trial"
                objectFit="contain"
                src="../images/free-trial-dark.png"
                alt="Free trial week" /> :
              <StaticImage
                className="free-trial"
                objectFit="contain"
                src="../images/free-trial.png"
                alt="Free trial week" />
            }
          </div>
          <div className="col-12 col-md-6">
            <Title>Free trial week</Title>
            <h2 className="body1">
              {`We like to think long term and build strong business relationships.\nThe first week is on us so you can see what we do before investing a single penny.`}
            </h2>
            <div className="action">
              <Link to="/contact">
                <Button
                  size="big"
                  variant="outlined"
                  bgColor={theme.body}
                  color={theme.buttonColor}
                >
                  Contact us
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </Content>
    </Wrapper>
  )
}

export default withTheme(FreeTrialBlock)
