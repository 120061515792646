import React from "react"
import styled from "styled-components"
import { colors } from "../theme"
import ProcessArrow from "./process-arrow"

const startIcon = require("src/images/process-start.svg")
const circleIcon = require("src/images/process-circle.svg")

const Wrapper = styled.div`
  position: relative;
  padding: 105px 0 20px;

  & .line {
    height: 2px;
    background: ${props => props.lineEndColor};
    width: 100%;
    position: absolute;
    top: 45px;
    left: 0;
    & .icon {
      height: 40px;
      width: 40px;
      background-repeat: no-repeat;
      position: absolute;
      top: -20px;
      left: 20px;
      background: url(${circleIcon});
    }
  }

  &.first {
    padding-right: 1px;
    & .line {
      background: linear-gradient(
        90deg,
        ${props => props.lineEndColor} 0%,
        ${props => props.lineStartColor} 100%
      );
      left: 20px;
      width: calc(100% - 20px);
      & .icon {
        height: 80px;
        width: 80px;
        background: url(${startIcon});
        top: -40px;
        left: -20px;
      }
    }
  }

  &.last {
    & .line {
      background: linear-gradient(
        90deg,
        ${props => props.lineEndColor} 0%,
        ${props => props.lineStartColor} 150%
      );
    }
  }
`

const ProcessItems = ({
  title,
  content,
  body,
  button,
  textColor = colors.black,
  bgColor = "transparent",
  ...rest
}) => {
  return (
    <Wrapper
      className="d-flex flex-column"
      {...rest}
      lineStartColor={bgColor}
      lineEndColor={textColor}
    >
      <div className="line">
        <div className="icon"></div>
      </div>
      <div style={{ color: textColor, backgroundColor: bgColor, padding: 20 }}>
        <div className="d-flex align-items-start">
          <p className="title2-Emphasis">{title}</p>
          {button && (
            <div style={{ marginLeft: "auto" }}>
              <ProcessArrow color={textColor} />
            </div>
          )}
        </div>
        <p className="body1">
          {content}
        </p>
      </div>
    </Wrapper>
  )
}

export default ProcessItems
